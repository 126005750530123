export type InstanceDataType = {
    instanceId: string;
    publicIpAddress: string;
    name: string;
    domain: string;
    shared: boolean;
}

export type LMSType = {
    id: string;
    name: string;
    domain: string;
}

export type EventType = {
    eventName: string;
    eventDescription?: string;
    count: number;
};

export type EventReportType = {
    domain: string;
    results: EventType[];
};

export type EventMapType = {
    [key: string]: string;
};

export enum ReportEnum {
    RUNNING_TASKS = "running-tasks",
    EVENTS = "daily-events"
};

export type TaskType = {
    uniqueid: string;
    type: string;
    classname: string;
    time: string;
    timestarted: string;
    hostname: string;
    pid: string;
};

export type TaskReportType = {
    domain: string;
    results: TaskType[];
};