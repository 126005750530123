import { EventType } from './types';

interface EventTableProps {
    events: EventType[];
}

const EventTable: React.FC<EventTableProps> = ({ events }) => {

    return (
        <table id="events-table" className="table table-bordered table-hover table-sm align-middle w-100 text-wrap">
            <thead>
                <tr>
                    <th scope="col">Event</th>
                    <th scope="col">Description</th>
                    <th scope="col">Count</th>
                </tr>
            </thead>
            <tbody>
                {events.map((event: EventType) => (
                    <tr key={event.eventName}>
                        <td>{event.eventName}</td>
                        <td>{event.eventDescription}</td>
                        <td>{event.count}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default EventTable;