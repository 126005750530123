import React, { ChangeEvent, useMemo } from "react";
import { Outlet, useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import "./css/App.css";
import { useState, useEffect } from "react";
import { ROLE, useAuth } from "../auth/AuthContext";
import { api } from "../api";
import { AxiosError } from "axios";
import DropdownMenu, { CustomDropdownOption } from "./DropdownMenu/DropdownMenu";

interface FunctionWithArguments {
  (...args: any): any;
}

interface DebouncedFunction<F extends FunctionWithArguments> {
  (...args: Parameters<F>): Promise<ReturnType<F>>;
}

interface DebounceReturn<F extends FunctionWithArguments>
  extends Array<DebouncedFunction<F> | (() => void)> {
  0: (...args: Parameters<F>) => Promise<ReturnType<F>>;
  1: () => void;
}

function debounce<F extends FunctionWithArguments>(
  fn: F,
  ms: number
): DebounceReturn<F> {
  let timer: ReturnType<typeof setTimeout>;

  const debouncedFunc: DebouncedFunction<F> = (...args) =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(...(args as unknown[])));
      }, ms);
    });

  const teardown = () => {
    clearTimeout(timer);
  };

  return [debouncedFunc, teardown];
}

function App() {
  const loaderData = useLoaderData() as { name: string, role: string };
  const { canUser, saveUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const localStorageRegion = window.localStorage.getItem(
    "moodle-console-region"
  );

  const [region, setRegion] = useState<string>(
    localStorageRegion ? localStorageRegion : "us-west-2"
  );
  const [filterSearch, setFilterSearch] = useState<string>("");

  const navigation = useNavigation();
  const navigate = useNavigate();

  useEffect(() => {
    window.localStorage.setItem("moodle-console-region", region);
    console.log("actualizando region en localStorage ", region);
  }, [region]);

  function handleChange(value: string) {
    console.log(value);
    navigate(value);
  }

  function handleRegionChange(value: string) {
    setRegion(value);
  }

  /* function handleAddLMS(e: React.FormEvent<HTMLButtonElement>) {
    e.preventDefault();
    console.log("add");
    navigate("instances/add");
  } */

  const [handleDebounceSearchInput] = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      setFilterSearch(e.target.value);
    }, 350);
  }, []);

  const getDataToExport = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const response = await api.get(`/instances/report?region=${region}`);
      const data = response.data;
      //console.log("response: ", data);

      setIsLoading(false);
      if (data.success) {
        const instances: [] = data.data;
        exportCSV(instances);
      }
      else {
        alert("Something went wrong during the process." );
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.error && data.message) {
          alert(data.message);
        } else alert("Something went wrong during the process.");
      } else alert(error.message);
    }
  };

  const exportCSV = (data: any[]) => {
    // Create CSV
    const headers = Object.keys(data[0]).join(',');
    //const csvRows = data.map(row => Object.values(row).join(','));
    const csvRows = data.map((row) => {
        // Wrap values in double quotes to avoid issues with commas
        if (row.hasOwnProperty('description')) {
          row.description = `"${row.description}"`;
        }
        return Object.values(row).join(',');
    });
    const csv = headers + '\n' + csvRows.join('\n');
    const href = 'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + csv);

    // Download file
    const link = document.createElement("a");
    link.setAttribute('href', href);
    link.setAttribute('download', 'InstancesReport.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportCSV = () => {
    getDataToExport();
};

  useEffect(() => {
    if (loaderData?.name) {
      saveUser(loaderData);
    }
  }, [loaderData, saveUser]);

  const createLMSOptions: CustomDropdownOption[] = [
    { value: "create-lms", label: "LMS", url: "instances/add", title: "Create LMS", restricted: false },
    { value: "create-shared-server",label: "Silo", url: "instances/add-shared-server", title: "Create Shared Server", restricted: true /*Solo visible para ROLE.SUPERADMIN*/ }
  ];

  const reportOptions: CustomDropdownOption[] = [
    { value: "usability-report", label: "Usability Reports", url: "/usability-report", title: "Usability Reports", restricted: false },
    { value: "performance-report", label: "Performance Reports", url: "/performance-report", title: "Performance Reports", restricted: false },
  ];

  return (
    <>
      <div
        className={
          isLoading || navigation.state === "loading" || navigation.state === "submitting"
            ? "loading"
            : ""
        }
      ></div>
      <nav id="main-navbar" className="navbar navbar-expand-md">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="/images/logo-black.png" alt="Logo" height="64" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="d-flex ms-auto">
              <select
                // defaultValue="instance"
                className="form-select form-select-sm me-3 instance-select"
                aria-label=".form-select-lg"
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              >
                <option value="instances">Instance</option>
                <option value="dbinstances">RDS</option>
              </select>
              <select
                className="form-select form-select-sm me-3 region-select"
                aria-label=".form-select-lg"
                value={region}
                onChange={(e) => {
                  handleRegionChange(e.target.value);
                }}
              >
                <option value="us-west-2">us-west-2</option>
                <option value="eu-central-1">eu-central-1</option>
              </select>
              {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) 
                ? <DropdownMenu options={createLMSOptions} buttonTitle="+" buttonClass="plus-button" />
                : null 
              }
              <button
                className="btn btn-light me-3 export-button"
                title="Export instances to CSV"
                onClick={() => {
                  handleExportCSV();
                }}
              ></button>
              <DropdownMenu options={reportOptions} buttonClass="chart-reports-button" />
{/*               <button
                className="btn btn-light me-3 chart-reports-button"
                title="Usability Reports"
                onClick={() => {
                  navigate('/usability-report');
                }}
              ></button> */}
              {canUser([ROLE.SUPERADMIN]) ?
                <button
                  className="btn btn-light me-3 dynamo-reports-button"
                  title="Instances DynamoDB Report"
                  onClick={() => {
                    navigate('/reports/dymano-instances');
                  }}
                ></button>
              : null }
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={handleDebounceSearchInput}
              />
            </div>
          </div>
        </div>
      </nav>
      <main className="container-fluid px-0">
        <Outlet context={{ region, setRegion, filterSearch }} />
      </main>
    </>
  );
}

export default App;
