import { FC, useEffect, useState } from "react";
import { api } from "../../api";
import { Link } from "react-router-dom";
//import { AxiosError } from "axios";


interface LMSRowProps {
  lms: {
    id: string;
    name: string;
    domain: string;
  };
  ip: string;
  addLoadingRow: (id: string) => void;
  removeLoadingRow: (id: string) => void;
}

interface LMSData {
    onlineUsers: string;
}

//let lmsCache: any = {};

const LMSRow: FC<LMSRowProps> = ({ lms, ip, addLoadingRow, removeLoadingRow }) => {

    const [onlineUsers, setOnlineUsers] = useState<string>("Not available yet");  
   
    useEffect(() => {
        const url = `/instances/${lms.domain}/online-users?ip=${ip}`;
        console.log('url:', url);
    
        const updateState = (data: LMSData) => {
          if (data.onlineUsers) 
            setOnlineUsers(data.onlineUsers);
        };
    
        //if (lmsCache[url]) return updateState(lmsCache[url]);
    
        if (lms.domain) {
          addLoadingRow(lms.id);
          api
            .get<LMSData>(url)
            .then(({ data }) => {
              console.log(`data for ${lms.domain}: `, data);
              //lmsCache[url] = data;
              updateState(data);
            })
            .catch(() => {
                console.log('entre aquiiiiiiiiiiiiii');
                setOnlineUsers("Not available");
            })
            .finally(() => {
                removeLoadingRow(lms.id);
            });
        }
    }, [lms, ip, addLoadingRow, removeLoadingRow]);

  return (
    <tr key={lms.id}>
      <td>{lms.name}</td>
      <td style={{ textAlign: "center" }}>{onlineUsers}</td>
      <td style={{ textAlign: "center" }}>
        <Link
          title="Events"
          className="options-table-instances information-icon"
          to={`events/${lms.name}/${lms.domain}`}
        ></Link>
      </td>
      <td style={{ textAlign: "center" }}>
        <Link
          title="Running tasks"
          className="options-table-instances information-icon"
          to={`running-tasks/${lms.name}/${lms.domain}`}
        ></Link>
      </td>
    </tr>
  );
}

export default LMSRow;