import { TaskType } from './types';

interface RunningTaskTableProps {
    tasks: TaskType[];
}

const RunningTaskTable: React.FC<RunningTaskTableProps> = ({ tasks }) => {

    // Formatear tiempo en segundos a minutos y segundos
    const formatTime = (seconds: string): string => {
        const numSeconds = parseInt(seconds, 10);
        const minutes = Math.floor(numSeconds / 60);
        const remainingSeconds = numSeconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    };

    // Formatear timestamp a fecha y hora
    const formatTimestamp = (timestamp: string): string => {
        const numTimestamp = parseInt(timestamp, 10);
        const date = new Date(numTimestamp * 1000); // Convertir de segundos a milisegundos
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Cambiar a true si deseas formato 12 horas
        });
    };

    return (
        <table id="events-table" className="table table-bordered table-hover table-sm align-middle w-100 text-wrap">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Time</th>
                    <th scope="col">Started</th>
                    <th scope="col">Host Name</th>
                    <th scope="col">PID</th>
                </tr>
            </thead>
            <tbody>
                {tasks.map(task => (
                    <tr key={task.uniqueid}>
                        <td>{task.classname}</td>
                        <td>{task.type}</td>
                        <td>{formatTime(task.time)}</td>
                        <td>{formatTimestamp(task.timestarted)}</td>
                        <td>{task.hostname}</td>
                        <td>{task.pid}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default RunningTaskTable;