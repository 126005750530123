import { useState } from "react";
import {
  useLoaderData,
  useNavigate,
  //useOutletContext,
} from "react-router-dom";
import { useAuth, ROLE  } from "../../../auth/AuthContext";
import { api } from "../../../api";
import { AxiosError } from "axios";

interface ILoaderModal {
  instanceId: string;
  //instanceIp: string;
  deployment: string;
  volumeId?: string;
  name?: string;
}

const LMSUpgradeModal = () => {
  const loader = useLoaderData() as ILoaderModal;
  //const region = useOutletContext();
  const { canUser } = useAuth();

  const instanceId = loader.instanceId;
  //const instanceIp = loader.instanceIp;
  const deployment = loader.deployment;// to use it in upgrade instance in future
  const volumeId = loader.volumeId;
  const name = loader.name;
  console.log('lms upgrade modal: ', loader);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lmsName, setLmsName] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const handleUpgradeClick = () => {
    if (!lmsName) {
      setError(true);
      return;
    }

    if (lmsName !== name) {
      alert(`Please enter the correct LMS name (${name}) to confirm upgrade request.`);
      setError(true);
      return;
    }
  
    setError(false);
    if (window.confirm(`Are you sure you want to upgrade the LMS ${name}?`)) {
      upgradeRequest();
    }
  };

  const upgradeRequest = async () => {
    if (
      !instanceId || !name ||
      (deployment === 'shared' && !volumeId )) 
    {
      alert("Invalid parameters.");
      return;
    }

    // Begin operation
    console.log("Begin the upgrade-request volume operation");
    setIsLoading(true); //show loading element

    //otherwise
    try {
      const response = await api.post(`/instances/${instanceId}/upgrade-request`, {
        parameters: {
          //instanceIp: instanceIp,
          volumeId: volumeId,
          name: name
        },
        deployment: deployment
      });

      const data = response.data;
      console.log("response: ", data);

      if (response.status === 200 && data.success) {
        alert("The upgrade request has been completed successfully.");
      }
    } catch (error: any) {
      if (error instanceof AxiosError && error.response) {
        const { data } = error.response;
        if (data.error && data.message) {
          alert(data.message);
        } else {
          alert("The upgrade request could not be completed.");
        }
      } else alert(error.message);
    }

    setIsLoading(false); //hide loading element
  };

  return (
    <>
      <div className="modal-overlay-second">
        <div className={isLoading  ? "loading" : "" }></div>
        <div className="modal-actions">
          <h4 className="title-modal-lms-actions">{name}</h4>
          <button
            className="close-modal-button"
            onClick={() => navigate(-1)}
          >
            x{" "}
          </button>
          <div className="modal-actions-body">
            <div className="section-modal-lms-action">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>Upgrade LMS</h5>
                </div>
              </div>
              {canUser([ROLE.ADMIN, ROLE.SUPERADMIN]) ? (
              <div className="section-content-lms-actions">
                  <div className="row mb-3">
                    <label
                      className="col-sm-4 col-form-label font-weight-500 text-end"
                      htmlFor="lmsName"
                    >
                      LMS Name
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        name="lmsName"
                        className={`form-control ${error ? 'wrong' : ''}`}
                        value={lmsName}
                        onChange={(e) => setLmsName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 text-end mr div-delete-instance">
                    <div className="col-sm-12">
                      <button
                        className="btn btn-custom btn-main"
                        onClick={() => handleUpgradeClick()}                       
                      >
                        Upgrade
                      </button>
                    </div>
                  </div>
              </div>
              ) : 'You do not have permission to perform this action.' }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LMSUpgradeModal;
