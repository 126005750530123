import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import { 
    InstanceDataType, 
    LMSType, 
    EventType, 
    EventReportType, 
    EventMapType, 
    TaskReportType, 
    ReportEnum 
} from "./types";
import { api } from "../../api";
import { AxiosError } from "axios";
import LMSRow from "./LMSRow";
import moodleEventMap from "./moodleEventMap.json";

type Results = EventReportType[] | TaskReportType[];

const PerformanceReport = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [instanceList, setInstanceList] = useState<InstanceDataType[]>([]);
    const [selectedInstance, setSelectedInstance] = useState<InstanceDataType>();
    const [lmsList, setLMSList] = useState<LMSType[]>([]);

    const [loadingRows, setLoadingRows] = useState<Set<string>>(new Set());

    const contextData = {
        //name: selectedInstance?.name,
        ip: selectedInstance?.publicIpAddress,
    };

    const addLoadingRow = useCallback((rowId: string) => {
        setLoadingRows((prev) => new Set(prev).add(rowId));
    }, []);

    const removeLoadingRow = useCallback((rowId: string) => {
        setLoadingRows((prev) => {
            const updated = new Set(prev);
            updated.delete(rowId);
            return updated;
        });
    }, []);

    const navigation = useNavigation();
    const navigate = useNavigate();

    const handleSelectChange = (selectedValue: string) => {
        if (selectedValue === "") {
            return;
        }

        setLMSList([]);
        // Find the instance object from the list
        const instance = instanceList.find(inst => inst.instanceId === selectedValue);
        console.log("instance selected: ", instance);
        if(instance) setSelectedInstance(instance);
    };

    const handleDownloadReport = async (report: ReportEnum) => {
        if (selectedInstance && selectedInstance.shared) {
            console.log("Download Report: ", report);
            try {
                setIsLoading(true);
                const domains = lmsList.map(lms => lms.domain);
                console.log("Domains: ", domains);
                const response = await api.post(`/instances/${selectedInstance.instanceId}/report/${report}`, {
                    instanceName: selectedInstance.name,
                    domains: domains,
                    ip: selectedInstance.publicIpAddress
                });
                const data = response.data;
                console.log("All events data: ", data);
                if (data.success && data.results && data.results.length > 0) {
                    console.log("All events loaded successfully.");
                    const results: Results = data.results;
                    const csvContent = report === ReportEnum.RUNNING_TASKS
                        ? generateCsvContentToTasks(results as TaskReportType[])
                        : generateCsvContentToEvents(results as EventReportType[]);
                    exportCSV(csvContent, report === ReportEnum.RUNNING_TASKS ? 'RunningTasksReport.csv' : 'EventsReport.csv');
                    setIsLoading(false);
                } else {
                    alert("No data to export.");
                    setIsLoading(false);
                }
            } catch (error: any) {
                setIsLoading(false);
                if (error instanceof AxiosError && error.response) {
                    const { data } = error.response;
                    if (data.error && data.message) {
                        alert(data.message);
                    } else alert("Something went wrong during the process.");
                } else alert(error.message);
            }
        }
    };

    const exportCSV = (csvContent: any, filename: string) => {
        console.log(csvContent);
        const href = 'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + csvContent);

        // Download CSV file
        const link = document.createElement("a");
        link.setAttribute('href', href);
        //link.setAttribute('download', 'EventsReport.csv');
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Formatear tiempo en segundos a minutos y segundos
    const formatTime = (seconds: string): string => {
        const numSeconds = parseInt(seconds, 10);
        const minutes = Math.floor(numSeconds / 60);
        const remainingSeconds = numSeconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
    };

    // Formatear timestamp a fecha y hora
    const formatTimestamp = (timestamp: string): string => {
        const numTimestamp = parseInt(timestamp, 10);
        let date = new Date(numTimestamp * 1000); // Convertir de segundos a milisegundos
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Cambiar a true si deseas formato 12 horas
        }).replace(',', ''); // Reemplaza la coma por un espacio para evitar problemas con el CSV
    };

    // Generar contenido del archivo CSV para las tareas en ejecución
    const generateCsvContentToTasks = (data: TaskReportType[]): string => {
        let csvContent = '';
        data.forEach((domainData: TaskReportType) => {
            csvContent += `Domain: ${domainData.domain}\n`; // Encabezado de dominio
            csvContent += 'Name,Type,Time,Started,Host Name,PID\n'; // Encabezado de tabla

            domainData.results.forEach(task => {
                csvContent += `${task.classname},${task.type},${formatTime(task.time)},${formatTimestamp(task.timestarted)},${task.hostname},${task.pid}\n`;
            });

            csvContent += '\n'; // Separación entre dominios
        });
        return csvContent;
    };

    // Generar contenido del archivo CSV para los eventos
    const generateCsvContentToEvents = (results: EventReportType[]): string => {
        // 1. Extraer eventos únicos
        const eventMap = new Map<string, { eventName: string; eventDescription: string }>();
        results.forEach((r: EventReportType) => {
            r.results.forEach((e: EventType) => {
                if (!eventMap.has(e.eventName)) {
                    eventMap.set(e.eventName, { eventName: e.eventName, eventDescription: e.eventDescription || '' });
                }
            });
        });
        let uniqueEvents = Array.from(eventMap.values());
        console.log("Unique events: ", uniqueEvents);

        // Mapear los eventos con su descripción si existe en el archivo moodleEventMap.json
        uniqueEvents = uniqueEvents.map((event) => {
            const eventDescription = (moodleEventMap as EventMapType)[event.eventName] || '';
            return {
                ...event,
                eventDescription,
            };
        });

        // 2. Construir la estructura de datos
        const csvRows: string[] = [];

        // Encabezado
        const header = ['Event Name', 'Description', ...results.map((r: EventReportType) => r.domain)];
        csvRows.push(header.join(','));
        //console.log('Header Rows: ', csvRows);

        // Filas de eventos
        uniqueEvents.forEach(event => {
            const row: (string | number)[] = [event.eventName, event.eventDescription];
            results.forEach((domainData: EventReportType) => {
                const eventEntry = domainData.results.find((e: EventType) => e.eventName === event.eventName);
                row.push(eventEntry ? eventEntry.count : 0);
            });
            csvRows.push(row.join(','));
        });

        // 3. Generar CSV
        const csvContent = csvRows.join('\n');
        return csvContent;
    }

    useEffect(() => {
        setIsLoading(true);
        api
            .get(`/instances/basic-list`)
            .then((response) => {
                const { data } = response;
                console.log("data: ", data);
                if(data.success && data.instances)
                    setInstanceList(data.instances);
                setIsLoading(false);
            })
            .catch((err) => {
                let message;

                if (err instanceof AxiosError && err.response) {
                let data = err.response.data;
                message = data.message;
                } else message = err.message;

                console.log(">>> [message]", message);
                setIsLoading(false);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (selectedInstance)
        if (selectedInstance.shared) {
            setIsLoading(true);
            api
                .get(`/volumes/${selectedInstance.instanceId}/basic-list`)
                .then((response) => {
                    const { data } = response;
                    console.log("data: ", data);
                    if (data.success && data.volumes) setLMSList(data.volumes);
                })
                .catch((err) => {
                    let message;
                    if (err instanceof AxiosError && err.response) {
                    let data = err.response.data;
                    message = data.message;
                    } else message = err.message;

                    console.log(">>> [message]", message);

                })
                .finally(() => setIsLoading(false)); 

        } else {
            //Populate the LMS list with the instance data
            setLMSList([{
                id: selectedInstance.instanceId, 
                name: selectedInstance.name, 
                domain: selectedInstance.domain
            }]);
        }
    }, [selectedInstance]);

    return (
        <>
            <div
                className={
                    isLoading || loadingRows.size > 0 || navigation.state === "loading"
                        ? "loading"
                        : ""
                }
            ></div>
            <nav id="main-navbar" className="navbar navbar-expand-md">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src="/images/logo-black.png" alt="Logo" height="64" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="d-flex ms-auto">
                            <button
                                className="btn btn-light me-3 back-button"
                                title="Instances"
                                onClick={() => {
                                    navigate("/home");
                                }}
                            ></button>
                        </div>
                    </div>
                </div>
            </nav>
            <main className="container-fluid px-0">
                <div className="div-green">
                    <div className="white-line"></div>
                </div>
                <div className="report-fields">
                    <div className="row mb-2">
                        <div className="col-6 col-sm-4 col-lg-2 me-5 mb-4">
                            <select
                                className="form-select form-select-sm me-3 report-select"
                                aria-label=".form-select-lg"
                                onChange={(e) => handleSelectChange(e.target.value)}  >
                                <option value="">INSTANCE</option>
                                {instanceList.map((instance) => (
                                    <option key={instance.instanceId} value={instance.instanceId}>
                                        {instance.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            {lmsList && lmsList.length > 0 && (
                                <div className="table-responsive">
                                    <table
                                        id="volumes-table"
                                        className=" table table-bordered table-hover table-sm align-middle w-100 text-wrap"
                                    >
                                        <thead>
                                            <tr>
                                                <th scope="col">CLIENT</th>
                                                <th scope="col">ACTIVE USERS</th>
                                                <th scope="col">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="me-3">EVENTS</span>  
                                                        {selectedInstance?.shared && (
                                                            <button 
                                                                title="Download all Moodle events from the LMSs"
                                                                className="btn btn-light download-button" 
                                                                onClick={() => handleDownloadReport(ReportEnum.EVENTS)}
                                                            ></button>
                                                        )}
                                                    </div>
                                                </th>
                                                <th scope="col">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span className="me-3">RUNNING TASKS</span>  
                                                        {selectedInstance?.shared && (
                                                            <button 
                                                                title="Download all Moodle Running Tasks from the LMSs"
                                                                className="btn btn-light download-button" 
                                                                onClick={() => handleDownloadReport(ReportEnum.RUNNING_TASKS)}
                                                            ></button>
                                                        )}
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lmsList?.map((lms: LMSType) => (
                                                <LMSRow
                                                    key={lms.id} 
                                                    lms={lms} 
                                                    ip={selectedInstance?.publicIpAddress || ""}
                                                    addLoadingRow={addLoadingRow}
                                                    removeLoadingRow={removeLoadingRow}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                    <Outlet context={contextData}/>
                </div>
            </main>
        </>
    );
};

export default PerformanceReport;