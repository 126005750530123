import { FC, useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../api";
import { AxiosError } from "axios";
import { EventType, EventMapType, ReportEnum, TaskType } from "./types";
import moodleEventMap from "./moodleEventMap.json";
import EventTable from "./EventTable";
import RunningTaskTable from "./RunningTaskTable";

interface ReportModalProps {
  report: ReportEnum;
}

const ReportModal: FC<ReportModalProps> = ({ report }) => {

  //const { name, domain, instanceIp } = useParams();
  const { name, domain } = useParams();
  const { ip } = useOutletContext<{ ip: string }>();
  console.log(domain, name, ip);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dataList, setDataList] = useState<EventType[] | TaskType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true); //show loading element
    const controller = new AbortController();
    // Timeout for request if it takes too long
    let requestTimer = setTimeout(() => {
      console.log('La solicitud está tardando demasiado.');
      controller.abort(); // Cancel request
      alert('The report is being generated. You will receive an email when it is ready. Please do not close the application and try again in a few minutes.');
      setIsLoading(false); //hide loading element
    }, 60000); // 60000 milliseconds = 1 minute

    // Obteniendo eventos del dia
    api
      .get(`/instances/${domain}/report/${report}?ip=${ip}`, { signal: controller.signal })
      .then(({ data }) => {
        // Cancel timer when response is received
        clearTimeout(requestTimer);
        if (data.success) {
          if (report === ReportEnum.RUNNING_TASKS) {
            setDataList(data.results);
          } else {
            // Mapping events
            data.results = data.results.map((event: EventType) => {
              const eventDescription = (moodleEventMap as EventMapType)[event.eventName] || '';
              return {
                ...event,
                eventDescription,
              };
            });
            console.log('events luego de mapeado: ', data.results);
            setDataList(data.results);
          }
        }
        setIsLoading(false); //hide loading element
      })
      .catch((err) => {
        // Cancel timer when occurs an error
        clearTimeout(requestTimer);

        let message;
        if (err instanceof AxiosError && err.response) {

          let data = err.response.data;
          message = data.message;
        } else message = err.message;

        console.log('err: ', err)
        console.log(">>> [message]", message);

        if (err.name !== 'CanceledError') { // If the request is canceled, don't show the alert
          setError(true);
          setErrorMessage(message);
          setIsLoading(false);
        }
        //setIsLoading(false);
      });

    return () => { controller.abort(); }; // Abort request when component is unmounted
  }, [domain, report, ip]);

  return (
    <>
      <div className="modal-overlay">
        <div className={isLoading ? "loading" : ""} ></div>
        <div className="modal-actions information-events">
          <button
            className="close-modal-button"
            onClick={() => { navigate(-1); }}
          > x </button>
          <h4 className="title-modal-information">
            {report === ReportEnum.RUNNING_TASKS ? "RUNNING TASKS" : "EVENTS"}
          </h4>
          <div className="modal-actions-body">
            <div className="section-modal-dbsnap">
              <div className="section-title">
                <div className="col-sm-12">
                  <h5>{name}</h5>
                </div>
              </div>
              <div className="section-content report">
                {error && errorMessage && (
                  <div className="alert alert-info">
                    <p>{errorMessage}</p>
                  </div>
                )}
                {!error && dataList && dataList.length > 0 && (
                  <div className="row">
                    <div className="col-12 text-center scrollit-eventlist">
                      {report === ReportEnum.EVENTS && (<EventTable events={dataList as EventType[]} /> )}
                      {report === ReportEnum.RUNNING_TASKS && (<RunningTaskTable tasks={dataList as TaskType[]} /> )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportModal;